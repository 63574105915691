import {PageContainerDPC, SvgIconDPC, PrintButtonDPC, SfogliatoreDPC} from '@dpc-fe/shared';
import {graphql} from 'gatsby';
import React from 'react';
import IconSfoglia from '@dpc-fe/shared/assets/sfoglia.svg';
import moment from "moment";
import 'moment/locale/it';
import {prop, getCategoriaPrimariaEN} from "../functions/functions";
import "moment/locale/it";

export default ({data, pageContext}) => {

    const breadcrumbItem = {
        name: data.node.title,
        slug: data.node.fields && data.node.fields.slug ? data.node.fields.slug : null,
        active: true
    };
    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                          //checkTorna={true}
                          //linkTorna={`/${pageContext.lang}${data.elenco?.fields?.slug}`}
                          //textTorna={data.elenco?.title}
                          menuItems={pageContext.menu}
                          slugTraduzione={data.node.relationships.field_riferimento_traduzione ? data.node.relationships.field_riferimento_traduzione.fields.slug : ''}
                          pageId={data.node.drupal_internal__nid}
                          title={data.node.title}
                          env={process.env.ENV}
                          lang={pageContext.lang}
                          elencoId={data.elenco?.drupal_internal__nid}
                          breadcrumbItem={breadcrumbItem}
                          immagineShare={data.node.relationships?.field_immagine_dettaglio ? process.env.PORTALE_URL + data.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL: process.env.PORTALE_URL + pageContext.socialImg?.relationships?.image?.localFile?.publicURL}
                          abstractShare={data.node.body?.processed ? data.node.body.processed.replace(/<\/?[^>]+(>|$)/g, "") : ''}
                          slug={process.env.PORTALE_URL + '/' + pageContext.lang + data.node.fields.slug}
                          minisiti={prop}
                          menuFooterItems={pageContext.menuFooter}
        >
            <section className="container">
                <div className="px-custom">
                    <article>
                        <div className="category-top">
                            <span className="data">{moment(data.node.field_data).format('D MMMM Y')}</span>
                        </div>
                        <h1>{data.node.field_titolo_esteso}</h1>
                        <div className="row">
                            {data.node.relationships.field_immagine_dettaglio &&
                            <div className="col-12 col-md-6 col-xl-3">
                                <img alt={data.node.relationships.field_immagine_dettaglio.field_alt}
                                     src={data.node.relationships.field_immagine_dettaglio.relationships?.field_immagine?.localFile?.publicURL}
                                     className="img-fluid w-100 mb-3"/>
                            </div>}
                            <div className="col-12 col-md-6 col-xl-5">
                                <div
                                    dangerouslySetInnerHTML={{__html: data.node.body ? data.node.body.processed : ''}}/>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <div className="it-list-wrapper">
                                    <ul className="it-list">
                                        <li>
                                            <div><span
                                                className="font-weight-semibold">Autore: </span> {data.node.field_autore}
                                            </div>
                                        </li>
                                        <li>
                                            <div><span
                                                className="font-weight-semibold">Curatore: </span> {data.node.field_curatore}
                                            </div>
                                        </li>
                                        <li>
                                            <div><span
                                                className="font-weight-semibold">Editore: </span> {data.node.field_editore}
                                            </div>
                                        </li>
                                        <li>
                                            <div><span
                                                className="font-weight-semibold">Lingua: </span> {data.node.field_lingua}
                                            </div>
                                        </li>
                                        <li>
                                            <div><span
                                                className="font-weight-semibold">Pagine: </span> {data.node.field_pagine}
                                            </div>
                                        </li>
                                        <li>
                                            <div><span
                                                className="font-weight-semibold">Anno di pubblicazione: </span> {data.node.field_anno_pubblicazione}
                                            </div>
                                        </li>
                                        <li>
                                            <div><span
                                                className="font-weight-semibold">Edizione: </span> {data.node.field_edizione}
                                            </div>
                                        </li>
                                        <li>
                                            <div><span
                                                className="font-weight-semibold">ISBN: </span> {data.node.field_isbn}
                                            </div>
                                        </li>
                                        <li>
                                            <div><span
                                                className="font-weight-semibold">Disponibilità: </span> {data.node.field_richiedi_una_copia ? "Yes" : "No"}
                                            </div>
                                        </li>
                                        <li>
                                            <div><span
                                                className="font-weight-semibold">Disponibilità online: </span> {(Array.isArray(data.node.relationships.field_allegati) &&
                                                data.node.relationships.field_allegati.length > 0) ? "Yes" : "No"}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="article-file-actions">
                                    {Array.isArray(data.node.relationships.field_allegati) &&
                                    data.node.relationships.field_allegati.length > 0 ?
                                        data.node.relationships.field_allegati.map(allegato => (

                                            <ul>
                                                <li>
                                                    <a href={process.env.PORTALE_URL + allegato.relationships.field_allegato?.localFile?.publicURL}
                                                       download>
                                                        <SvgIconDPC name='download'/>
                                                        <span>Scarica</span>
                                                    </a>
                                                </li>
                                                {(data.node.field_versione_sfogliabile && allegato.relationships.field_allegato?.localFile?.extension === 'pdf') &&
                                                <SfogliatoreDPC
                                                    url={process.env.PORTALE_URL + allegato.relationships.field_allegato?.localFile?.publicURL}/>
                                                }
                                                {data.node.field_richiedi_una_copia &&
                                                <li>
                                                    <a href={`mailto:${data.node.field_richiedi_una_copia}`}>
                                                        <SvgIconDPC name='files'/>
                                                        <span>Richiedi una copia</span>
                                                    </a>
                                                </li>
                                                }
                                            </ul>

                                        )) :
                                        <>
                                            {data.node.field_richiedi_una_copia &&
                                            <ul>
                                                <li>
                                                    <a href={`mailto:${data.node.field_richiedi_una_copia}`}>
                                                        <SvgIconDPC name='files'/>
                                                        <span>Richiedi una copia</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            }
                                        </>

                                    }
                                </div>

                            </div>
                        </div>
                    </article>

                    <PrintButtonDPC/>

                </div>
            </section>
        </PageContainerDPC>
    );
};


export const query = graphql`
query($slug: String!) {
  node:nodePubblicazione (fields: { slug: { eq: $slug } }){
    drupal_internal__nid
    field_titolo_esteso
    fields {
      slug
    }
    title
    body {
      processed
    }
    field_categoria_primaria
    field_data
    field_autore
    field_anno_pubblicazione
    field_curatore
    field_editore
    field_edizione
    field_lingua
    field_isbn
    field_pagine
    field_richiedi_una_copia
    field_versione_sfogliabile
    relationships {
      field_sottodominio {
        name
      }
      field_riferimento_traduzione {
          fields {
            slug
          }
      }
      field_immagine_anteprima {
        field_alt
        field_didascalia
        relationships {
          image:field_immagine{
              localFile {
			    publicURL
                childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
        }
        }
      }
      field_immagine_dettaglio {
        field_alt
        field_didascalia
        relationships {
          field_immagine {
            localFile {
            publicURL
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      field_allegati {
        title
        relationships {
          field_sottodominio {
            name
          }
          field_allegato {
            filesize
            filename
            localFile {
              publicURL
              extension
            }
          }
        }
      }
    }
  },
  elenco:nodeElencoPubblicazioni (field_codice_lingua: {eq: true}, relationships: {field_sottodominio: {name: {eq: "Portale"}}}) {
      title
      field_titolo_esteso
      fields {
        slug
      }
      drupal_internal__nid
  },
}
`;
